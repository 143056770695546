import {
  initClassicPaginationUtils,
  parsePageParam,
  stringifyPageParam,
} from '@wix/comments-ooi-client/classic-pagination';

const classicPagintationUtils = initClassicPaginationUtils({
  parsePageParam,
  stringifyPageParam,
  pageParamName: 'page',
});

export default classicPagintationUtils;
