import { FeedType } from '../models';
import { createFeedTypeQueryParam } from '../create-feed-type-query-param';
import { setSavedAction } from '../../../../common/store/saved-action/set-saved-action';
import { GO_TO_MY_POSTS } from '../../../constants/interactions';
import { requestLoginPromisified } from '../../../actions/request-login';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';
import { RootState } from '../../../reducers/root-state';
import { getCurrentUser } from '../../../../common/store/current-user/current-user-selectors';

export const navigateToMainTab = (feedType: FeedType | null) => (
  dispatch: (action: any) => void,
  getState: () => RootState,
) => {
  const isAuthenticated = !!getCurrentUser(getState());

  if (feedType === FeedType.MyPosts && !isAuthenticated) {
    dispatch(
      setSavedAction({
        action: GO_TO_MY_POSTS,
        args: `/?${createFeedTypeQueryParam(FeedType.MyPosts)}`,
      }),
    );
    return dispatch(requestLoginPromisified(['login']));
  }

  dispatch(navigateWithinForum(`${feedType ? `/?${createFeedTypeQueryParam(feedType)}` : '/'}`));
};
