import { SET_PAGER_STATE } from '../actions/comments-pagination-actions';

export function commentsPagination(
  state = { commentsPaginationConfig: {}, pagerState: { type: 'NONE' } },
  action,
) {
  switch (action.type) {
    case SET_PAGER_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
