import { Ordering } from '@wix/comments-ooi-client/controller';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

export const PAGINATION_CONFIG = {
  replyShowMoreLimit: 8,
  ordering: Ordering.LATEST_FIRST,
  initialPage: {
    offset: 0,
    commentLimit: 20,
    replyLimit: 0,
  },
  pagination: {
    commentLimit: 20,
    replyLimit: 0,
  },
  maxPagesBeforeDrop: {
    commentPages: 2,
    replyPages: 4,
  },
};

export const PAGINATION_CONFIG_MOBILE = {
  replyShowMoreLimit: 8,
  ordering: Ordering.LATEST_FIRST,
  initialPage: {
    offset: 0,
    commentLimit: 10,
    replyLimit: 0,
  },
  pagination: {
    commentLimit: 10,
    replyLimit: 0,
  },
  maxPagesBeforeDrop: {
    commentPages: 4,
    replyPages: 4,
  },
};

export const getPaginationConfig = state =>
  getIsMobile(state) ? PAGINATION_CONFIG_MOBILE : PAGINATION_CONFIG;
